<template>
  <base-material-dialog
    :value="value"
    icon="mdi-account-supervisor-circle"
    title="Mannschaften bearbeiten"
    color="primary"
    :actions="[ 'close' ]"
    @close="close"
    @esc="close"
  >
    <v-card
      flat
      class="mt-8"
    >
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="add()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-data-table
        :headers="headers"
        :items="teams"
        :items-per-page="15"
        :mobile-breakpoint="0"
      >
        <template #item.name="{item}">
          <base-edit-dialog
            :value="item.name"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savefield(id, 'name', value)"
          />
        </template>
        <template #item.gender="{item}">
          <base-edit-dialog-select
            :value="item.gender"
            :id="item._id"
            :items="[
              { text: 'männlich', value: 'm' },
              { text: 'weiblich', value: 'w' },
              { text: 'divers', value: 'd' },
              { text: 'mixed', value: 'mixed' },
            ]"
            label="Geschlecht"
            :clearable="false"
            @input="({ value, id }) => savefield(id, 'gender', value, 'GenderType')"
          />
        </template>
        <template #item.sports="{item}">
          <base-edit-dialog-select
            :value="item.sports"
            :id="item._id"
            :items="[
              { text: 'Gerätturnen', value: 'gt' },
            ]"
            label="Sportart"
            :clearable="false"
            @input="({ value, id }) => savefield(id, 'sports', value, 'GenderType')"
          />
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.identifiers="{item}">
          <v-btn
            text
            small
            fab
            @click="openidentifiers(item)"
          >
            {{ (item.identifiers || []).length }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <identifiers
      v-model="identifiersdialog.open"
      :id="identifiersdialog.id"
      :identifiers="identifiersdialog.identifiers"
      collection="Clubteam"
    />
  </base-material-dialog>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'
import Identifiers from '../../core/dialogs/identifiers'

export default {
  name: 'clubteams',

  components: {
    Identifiers
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    teams: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    Club: {},
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Geschlecht',
        value: 'gender',
        sortable: false
      },
      {
        text: 'Sportart',
        value: 'sports',
        sortable: false
      },
      {
        text: 'IDs',
        value: 'identifiers',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Löschen',
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ],
    identifiersdialog: {
      open: false,
      identifiers: [],
      id: ''
    }
  }),

  methods: {
    add () {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: IClubTeam!) {
            ClubUpdateTeamAdd(id: $id, team: $team) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          team: {
            name: this.Club?.name || '',
            gender: 'mixed',
            sports: 'gt'
          }
        }
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) {
            ClubteamDelete(id: $id) {
              _id
            }
          }
        `,
        variables: {
          id
        }
      })
    },
    savefield (id, field, value, type) {
      if (!type) type = 'String'

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            ClubteamUpdate${q}(id: $id, ${field}: $${field}) { _id }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    },
    close () {
      this.$emit('input', false)
    },
    openidentifiers (item) {
      this.identifiersdialog.id = item._id || ''
      this.identifiersdialog.identifiers = item.identifiers || []
      this.identifiersdialog.open = true
    }
  },

  watch: {
    teams: {
      deep: true,
      handler (val) {
        if (!this.identifiersdialog.open) return
        this.identifiersdialog.identifiers = val.find(c => c._id === this.identifiersdialog.id)?.identifiers || []
      }
    }
  },

  apollo: {
    Club: {
      query: gql`
        query($id: UUID!) { Club(id: $id) { name }}
      `,
      variables () {
        return { id: this.id }
      },
      skip () {
        return !this.id
      }
    }
  }
}
</script>

<style scoped>

</style>
