<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="create()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="headers"
        :items="ClubFind"
        mobile-breakpoint="0"
        sort-by="name"
        :search="filter"
        :items-per-page="15"
      >
        <template #item.name="{item}">
          <base-edit-dialog
            :value="item.name"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savefield(id, 'name', value)"
          />
        </template>
        <template #item.url="{item}">
          <base-edit-dialog
            :value="item.url"
            :id="item._id"
            label="Homepage"
            @input="({ value, id }) => savefield(id, 'url', value)"
          />
        </template>
        <template #item.teams="{item}">
          <v-btn
            text
            small
            fab
            @click="open(item)"
          >
            {{ (item.teams || []).length }}
          </v-btn>
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.identifiers="{item}">
          <v-btn
            text
            small
            fab
            @click="openidentifiers(item)"
          >
            {{ (item.identifiers || []).length }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <clubteams
      v-model="dialog.open"
      :teams="dialog.teams"
      :id="dialog.id"
    />

    <identifiers
      v-model="identifiersdialog.open"
      :id="identifiersdialog.id"
      :identifiers="identifiersdialog.identifiers"
      collection="Club"
    />
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { apolloDefault, useGraphQL } from '@/plugins/graphql'

import Clubteams from '../dialogs/clubteams'
import Identifiers from '../../core/dialogs/identifiers'

const query = `
  _id
  name
  logo
  url
  teams {
    _id
    name
    gender
    sports
    identifiers { name value }
  }
  identifiers { name value }
`

export default {
  name: 'clubs',

  components: {
    Clubteams,
    Identifiers
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    ClubFind: [],
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      /* {
        text: 'Logo',
        value: 'logo',
        sortable: false
      }, */
      {
        text: 'Homepage',
        value: 'url',
        sortable: false
      },
      {
        text: 'IDs',
        value: 'identifiers',
        sortable: false
      },
      {
        text: 'Mannschaften',
        value: 'teams',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Löschen',
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ],
    filter: '',
    dialog: {
      open: false,
      teams: [],
      id: ''
    },
    identifiersdialog: {
      open: false,
      identifiers: [],
      id: ''
    }
  }),

  methods: {
    create () {
      this.mutate({
        mutation: gql`
          mutation { ClubCreate { ${query} }}
          `
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) { ClubDelete(id: $id) }
          `,
        variables: {
          id
        }
      })
    },
    savefield (id, field, value, type) {
      if (!type) type = 'String'

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            ClubUpdate${q}(id: $id, ${field}: $${field}) { ${query} }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    },
    open (item) {
      this.dialog.teams = item.teams || []
      this.dialog.id = item._id
      this.dialog.open = true
    },
    openidentifiers (item) {
      this.identifiersdialog.id = item._id || ''
      this.identifiersdialog.identifiers = item.identifiers || []
      this.identifiersdialog.open = true
    }
  },

  watch: {
    ClubFind: {
      deep: true,
      handler (val) {
        if (this.dialog.open) {
          this.dialog.teams = val.find(c => c._id === this.dialog.id)?.teams || []
        }
        if (this.identifiersdialog.open) {
          this.identifiersdialog.identifiers = val.find(c => c._id === this.identifiersdialog.id)?.identifiers || []
        }
      }
    }
  },

  apollo: apolloDefault('Club', query)
}
</script>

<style scoped>

</style>
