var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-material-dialog',{attrs:{"value":_vm.value,"icon":"mdi-account-supervisor-circle","title":"Mannschaften bearbeiten","color":"primary","actions":[ 'close' ]},on:{"close":_vm.close,"esc":_vm.close}},[_c('v-card',{staticClass:"mt-8",attrs:{"flat":""}},[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v(" fa-plus ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.teams,"items-per-page":15,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('base-edit-dialog',{attrs:{"value":item.name,"id":item._id,"label":"Name"},on:{"input":({ value, id }) => _vm.savefield(id, 'name', value)}})]}},{key:"item.gender",fn:function({item}){return [_c('base-edit-dialog-select',{attrs:{"value":item.gender,"id":item._id,"items":[
            { text: 'männlich', value: 'm' },
            { text: 'weiblich', value: 'w' },
            { text: 'divers', value: 'd' },
            { text: 'mixed', value: 'mixed' },
          ],"label":"Geschlecht","clearable":false},on:{"input":({ value, id }) => _vm.savefield(id, 'gender', value, 'GenderType')}})]}},{key:"item.sports",fn:function({item}){return [_c('base-edit-dialog-select',{attrs:{"value":item.sports,"id":item._id,"items":[
            { text: 'Gerätturnen', value: 'gt' },
          ],"label":"Sportart","clearable":false},on:{"input":({ value, id }) => _vm.savefield(id, 'sports', value, 'GenderType')}})]}},{key:"item.delete",fn:function({item}){return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}},{key:"item.identifiers",fn:function({item}){return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.openidentifiers(item)}}},[_vm._v(" "+_vm._s((item.identifiers || []).length)+" ")])]}}])})],1),_c('identifiers',{attrs:{"id":_vm.identifiersdialog.id,"identifiers":_vm.identifiersdialog.identifiers,"collection":"Clubteam"},model:{value:(_vm.identifiersdialog.open),callback:function ($$v) {_vm.$set(_vm.identifiersdialog, "open", $$v)},expression:"identifiersdialog.open"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }